import { Empty } from 'antd';
import { orderBy } from 'lodash';
import { useContext } from 'react';
import moment from 'moment';

import { ProgramTrendingDataContext } from '../../contexts/ProgramTrendingDataContext';
import {
  ABOVE_TARGET,
  BELOW_TARGET,
  COLOR_ABOVE_TARGET,
  COLOR_BELOW_TARGET,
  DATE_ID_FORMAT,
} from '../../common/constants';
import { Column } from '@ant-design/charts';
import { IProgramTrendingData } from '../../types/metrics';
import { ColumnConfig } from '@ant-design/charts/es';
import { customTargetLegend, getTargetColor, getTargetLabel } from '../../utils/chartUtils';

interface IData {
  month: string;
  value: number;
  category: string;
}

export const ProgramTreding = () => {
  const { programDatas } = useContext<IProgramTrendingData>(ProgramTrendingDataContext);
  const data = orderBy(programDatas, (item) => item.dateId).map(
    (p) =>
      ({
        month: moment(p.dateId, DATE_ID_FORMAT).format('MMM YY'),
        value: Number(p.score.toFixed(2)),
        category: getTargetLabel(p.score),
      } as IData)
  );
  const config: ColumnConfig = {
    data: data,
    xField: 'month',
    yField: 'value',
    label: {
      position: 'top',
      style: {
        fill: '#aaa',
      },
    },
    seriesField: 'category',
    color: ({ category }: IData) => {
      return getTargetColor(category);
    },
    yAxis: {
      max: 1,
    },
    columnWidthRatio: 0.4,
    legend: {
      position: 'top',
      flipPage: false,
      items: customTargetLegend,
    },
    appendPadding: 10,
    annotations: [
      {
        type: 'line',
        start: [-0.5, ABOVE_TARGET],
        end: [data.length, ABOVE_TARGET],
        text: {
          content: 'Above Target',
          offsetX: 70,
          style: { textAlign: 'right' },
        },
        style: {
          stroke: COLOR_ABOVE_TARGET,
          lineDash: [2, 2],
        },
      },
      {
        type: 'line',
        start: [-0.5, BELOW_TARGET],
        end: [data.length, BELOW_TARGET],
        text: {
          content: 'Below Target',
          offsetX: 70,
          style: { textAlign: 'right' },
        },
        style: {
          stroke: COLOR_BELOW_TARGET,
          lineDash: [2, 2],
        },
      },
    ],
  };
  if (!data?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    return <Column {...config} />;
  }
};
