/* eslint-disable react/display-name */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Modal, notification, Radio, Row, Select, TablePaginationConfig } from 'antd';
import layout from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  createSentimentMetric,
  deleteSentimentMetric,
  fetchSentiment,
  updateSentimentMetric,
} from '../../apis/fetchsentiment';
import { deleteTeam, fetchTeams } from '../../apis/teamClient';
import { DUPLICATE_TEAM_MESSAGE, GENERIC_MESSAGE, USER_PAGE_NUMBER } from '../../common/constants';
import { EditableColumn, EditableTable } from '../../components/EditableTable';
import { ErrorBox } from '../../components/ErrorBox';
import { TeamContext } from '../../contexts/TeamContext';
import { useFetch } from '../../hooks/useFetch';
import { PageTitle } from '../../layouts/AdminLayout.styled';
import {
  ConfiguredType,
  PosNegSentimentType,
  SentimentMetricModel,
  SentimentMetricResponse,
} from '../../types/metrics';
import { SelectOptions, TeamResponse, TeamType } from '../../types/team';
import { deleteItem, insertItem, updateItem } from '../../utils/tableUtils';
import './KpiManagement.scss';

const openNotification = (statusCode: number, message: string) => {
  const ERROR_MESSAGE = message === 'DuplicateTeamName' ? DUPLICATE_TEAM_MESSAGE : GENERIC_MESSAGE;
  notification.error({
    message: `Error: ${statusCode}`,
    description: `${ERROR_MESSAGE}`,
    duration: 2,
  });
};
//=====================================================================

const { Search } = Input;
const getParentType = (type: TeamType): TeamType => {
  switch (type) {
    case 'Team':
      return 'Engagement';
    case 'Engagement':
      return 'Department';

    case 'Department':
      return 'Program';
    default:
      return null;
  }
};

export const KpiManagement = () => {
  const { data: originalData, error: fetchError } = useFetch(fetchTeams);
  const [data, setData] = useState<TeamResponse[]>();
  const [activeType, setActiveType] = useState<TeamType>('Team');
  const [messageSentiment, setMessageSentiment] = useState<string>();
  const [addSentimentDialog, showAddSentimentDialog] = useState(false);
  const [hasError, setHasError] = useState<Response>();
  const [sentimentMetricData, setSentimentMetricData] = useState<SentimentMetricResponse[]>();
  const [formSentiment] = Form.useForm<SentimentMetricModel>();
  const { data: originalSentimentMetrics } = useFetch(fetchSentiment);
  const searchEl = useRef(null);
  const [activeMetrics, setActiveMetrics] = useState<TableType>('KPI');
  const [page, setPage] = useState(1);
  type TableType = 'KPI' | 'Sentiment';

  useEffect(() => {
    setData(originalData);
  }, [originalData]);

  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 5 }}>
          <Search
            ref={searchEl}
            allowClear
            onChange={(e: any) => {
              if (e._reactName === 'onChange') {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              } else {
                clearFilters();
              }
            }}
            placeholder="Filter By Name"
            onSearch={() => handleSearch(selectedKeys, confirm, dataIndex)}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            value={selectedKeys[0]}
            style={{ width: 200 }}
          />
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => {
      return text;
    },
  });
  const tableOptions: SelectOptions<TableType>[] = [
    {
      label: 'KPI',
      value: 'KPI',
    },
    {
      label: 'Sentiment',
      value: 'Sentiment',
    },
  ];
  const configuredOptions: SelectOptions<ConfiguredType>[] = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];
  const posNegSentimentOptions: SelectOptions<PosNegSentimentType>[] = [
    {
      label: '1',
      value: 1,
    },
    {
      label: '0',
      value: 0,
    },
    {
      label: '-1',
      value: -1,
    },
  ];
  const columns: EditableColumn<TeamResponse>[] = [
    {
      title: '#',
      width: '10px',
      render: (value, record, index) => (page - 1) * USER_PAGE_NUMBER + index + 1,
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      editable: true,
      rules: [{ required: true, message: 'This field is required' }],
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Project',
      dataIndex: 'parentId',
      editable: true,
      inputType: 'select',
    },
    {
      title: 'Is Configured?',
      dataIndex: 'isConfigured',
      inputType: 'select',
      editable: true,
      options: configuredOptions,
      align: 'center',
    },
  ];
  const [tableColumns, setTableColumns] = useState(columns);
  const SentimentColumns: EditableColumn<SentimentMetricResponse>[] = [
    {
      title: 'Sentiment',
      dataIndex: 'name',
      inputType: 'text',
      editable: true,
    },
    {
      title: 'Pos/Neg Attribute',
      dataIndex: 'posNegAttributeValue',
      inputType: 'select',
      options: posNegSentimentOptions,
      editable: true,
    },
  ];
  useEffect(() => {
    if (!data) {
      return;
    }
    const newColumns = [...columns];
    const index = newColumns.findIndex((item) => item.dataIndex === 'parentId');
    const parentCol = newColumns[index];

    parentCol.options = data
      .filter((item) => item.type === getParentType(activeType))
      .map((item) => ({ value: item.id, label: item.name }));

    setTableColumns(newColumns);
  }, [activeType, data, page]);

  useEffect(() => {
    setSentimentMetricData(originalSentimentMetrics);
  }, [originalSentimentMetrics]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const onSaveSentiment = async (id: number, { name, posNegAttributeValue }: SentimentMetricResponse) => {
    try {
      await updateSentimentMetric(id, { name, posNegAttributeValue });
      const newData = updateItem({ id, name, posNegAttributeValue }, sentimentMetricData);
      setSentimentMetricData(newData);
    } catch (error) {
      setHasError(error);
      if (error.status === 400) openNotification(400, 'The sentiment name is out of word limit or already exist.');
    }
  };

  const onDeleteSentiment = async (id: number) => {
    try {
      await deleteSentimentMetric(id);
      const newData = deleteItem(id, sentimentMetricData);
      setSentimentMetricData(newData);
    } catch (error) {
      openNotification(400, 'Delete failed!');
    }
  };
  const onSubmitSentiment = async () => {
    try {
      await formSentiment.validateFields();
    } catch (error) {
      setHasError(error);
      setMessageSentiment(error.status === 400 && 'The sentiment name is out of word limit or already exist.');
      return;
    }

    try {
      const { name, posNegAttributeValue } = formSentiment.getFieldsValue();
      const newSentiment = await createSentimentMetric({
        name,
        posNegAttributeValue,
      });
      const newData = insertItem(newSentiment, sentimentMetricData);
      setSentimentMetricData(newData);
      showAddSentimentDialog(false);
    } catch (error) {
      setHasError(error);
      setMessageSentiment(error.status === 400 && 'The sentiment name is out of word limit or already exist.');
    }
  };
  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
  };
  const onFormValueChanges = () => {
    setHasError(undefined);
  };
  const onDelete = async (id: number) => {
    try {
      await deleteTeam(id);
      const newData = deleteItem(id, data);
      setData(newData);
    } catch (error) {
      console.error(error);
    }
  };

  const tableData = data?.filter((item) => item.type === activeType);

  document.title = 'KPI Management';
  return (
    <Card className="page-manage-teams">
      <Helmet>
        <title>KPI Management</title>
      </Helmet>
      <PageTitle>
        <Title level={3}>KPI Management</Title>
      </PageTitle>
      <Row className="filter-container">
        <Col className="heatmap-measure-options">
          <label className="heatmap-filter-label">Type</label>
          <Radio.Group
            options={tableOptions}
            onChange={({ target: { value } }) => setActiveMetrics(value)}
            value={activeMetrics}
            optionType="button"
            buttonStyle="solid"
            size="large"
          />
        </Col>
      </Row>
      {activeMetrics === 'KPI' ? (
        <EditableTable
          data={tableData}
          columns={tableColumns}
          onDelete={onDelete}
          onChange={onChange}
          ModifyTeamWeight={true}
        />
      ) : (
        <>
          <Button className="btn-add-new-item" type="primary" onClick={() => showAddSentimentDialog(true)}>
            Add Sentiment
          </Button>
          <EditableTable
            data={sentimentMetricData}
            columns={SentimentColumns}
            onSave={onSaveSentiment}
            onDelete={onDeleteSentiment}
          />
        </>
      )}

      <Modal
        title="Add New Sentiment"
        visible={addSentimentDialog}
        onOk={onSubmitSentiment}
        onCancel={() => showAddSentimentDialog(false)}
      >
        <ErrorBox error={hasError} message={messageSentiment} />
        <Form
          {...layout}
          className="add-sentiment-modal"
          form={formSentiment}
          name="basic"
          initialValues={{ remember: true, posNegAttributeValue: 1 }}
          onValuesChange={onFormValueChanges}
        >
          <Form.Item label="Sentiment" name="name" rules={[{ required: true, message: 'This field is required!' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Pos/Neg"
            name="posNegAttributeValue"
            rules={[
              {
                required: true,
                message: 'The value must be [-1:1]',
              },
            ]}
          >
            <Select options={posNegSentimentOptions} defaultValue="1" />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};
