import { Empty } from 'antd';
import { groupBy } from 'lodash';
import { useContext } from 'react';
import moment from 'moment';
import { Area } from '@ant-design/charts';

import { ProgramTrendingDataContext } from '../../contexts/ProgramTrendingDataContext';
import {
  DATE_ID_FORMAT,
  COLOR_ABOVE_TARGET,
  COLOR_BELOW_TARGET,
  COLOR_ON_TARGET,
  MONTH_PICKER_FORMAT,
  BELOW_TARGET,
  ABOVE_TARGET,
} from '../../common/constants';
import { IProgramTrendingData } from '../../types/metrics';
import { AreaConfig } from '@ant-design/charts/es';
import { customTargetLegend } from '../../utils/chartUtils';

export interface IData {
  month: string;
  key: string;
  value: number;
}

type TeamType = 'Engagement' | 'Team';

interface IProps {
  type: TeamType;
}

export const EngagementTrending = ({ type }: IProps) => {
  const BELOW_TARGET_LABEL = 'Below Target';
  const ON_TARGET_LABEL = 'On Target';
  const ABOVE_TARGET_LABEL = 'Above Target';
  const { engagementDatas, teamDatas } = useContext<IProgramTrendingData>(ProgramTrendingDataContext);

  const dataNotNull =
    type === 'Engagement'
      ? engagementDatas?.filter((item) => item.score != null)
      : teamDatas?.filter((item) => item.score != null);

  const aggregateData = groupBy(dataNotNull, (item) => item.dateId);
  const data: IData[] = [];

  Object.keys(aggregateData).forEach((item) => {
    const belowTarget: IData = {
      month: moment(item, DATE_ID_FORMAT).format('MMM YY'),
      key: BELOW_TARGET_LABEL,
      value: aggregateData[item].filter((item) => item.score < BELOW_TARGET).length,
    };
    const onTarget: IData = {
      month: moment(item, DATE_ID_FORMAT).format('MMM YY'),
      key: ON_TARGET_LABEL,
      value: aggregateData[item].filter((item) => BELOW_TARGET <= item.score && item.score < ABOVE_TARGET).length,
    };
    const aboveTarget: IData = {
      month: moment(item, DATE_ID_FORMAT).format('MMM YY'),
      key: ABOVE_TARGET_LABEL,
      value: aggregateData[item].filter((item) => ABOVE_TARGET <= item.score).length,
    };

    data.push(aboveTarget);
    data.push(onTarget);
    data.push(belowTarget);
  });
  const config: AreaConfig = {
    data: data,
    xField: 'month',
    yField: 'value',
    seriesField: 'key',
    label: {
      style: {
        fill: '#aaa',
      },
    },
    legend: {
      position: 'top',
      flipPage: false,
      items: customTargetLegend,
    },
    color: [COLOR_ABOVE_TARGET, COLOR_ON_TARGET, COLOR_BELOW_TARGET],
  };

  if (!data?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    return <Area {...config} />;
  }
};
