import { Column } from '@ant-design/charts';
import { ColumnConfig } from '@ant-design/charts/es';
import { useContext } from 'react';
import { Empty } from 'antd';
import { getNpsCategory, getNpsColor, getTssCategory, getTssColor } from '../../utils/chartUtils';
import { TssDataContext } from '../../contexts/TssDataContext';

type TeamType = 'Engagement' | 'Team';

interface IProps {
  type: TeamType;
}

export const EngagementTss = ({ type }: IProps) => {
  const { engagmentActuals, teamActuals } = useContext(TssDataContext);

  const actualsNotNull =
    type === 'Engagement'
      ? engagmentActuals?.filter((item) => item.tss != null)
      : teamActuals?.filter((item) => item.tss != null);

  if (!actualsNotNull?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  let tssDistribution = [];
  for (let i = 0; i <= 5; i++) {
    switch (i) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
        tssDistribution.push({
          tss: `${i}`,
          value: actualsNotNull.filter((element) => element.tss === i).length,
          category: getTssCategory(i),
        });
        tssDistribution.push({
          tss: `>${i}`,
          value: actualsNotNull.filter((element) => i < element.tss && element.tss < i + 1).length,
          category: getTssCategory(i),
        });
        break;
      case 5:
        tssDistribution.push({
          tss: `${i}`,
          value: actualsNotNull.filter((element) => element.tss === i).length,
          category: getTssCategory(i),
        });
        break;
    }
  }

  var config: ColumnConfig = {
    seriesField: 'category',
    xField: 'tss',
    yField: 'value',
    xAxis: {
      label: {
        autoHide: false,
      },
    },
    color: ({ category }) => {
      const label = category.split(' (')[0];
      return getTssColor(label);
    },
    columnWidthRatio: 0.9,
    label: {},
    tooltip: {
      formatter: (datum) => {
        return {
          name: `${datum.category} (${datum.tss})`,
          value: datum.value,
        };
      },
    },
    data: tssDistribution,
    legend: {
      position: 'top',
      flipPage: false,
    },
  };

  return <Column {...config} height={300} />;
};
